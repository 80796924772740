@mixin size($width, $height: $width, $units: px ) {
  width: $width + $units;
  height: $height + $units;
}

@mixin placeholder($class) {
  .#{$class}::-webkit-input-placeholder {
    @content;
  }
  .#{$class}:-moz-placeholder {
    @content;
  }
  .#{$class}::-moz-placeholder {
    @content;
  }
  .#{$class}:-ms-input-placeholder {
    @content;
  }
}

@mixin font-face(
  $font-family,
  $file-path,
  $weight: normal,
  $style: normal,
  $asset-pipeline: false
) {
  @font-face {
    font-family: $font-family;
    font-weight: $weight;
    font-style: $style;
    
    src: url("#{$file-path}.woff") format("woff"),
    url("#{$file-path}.woff2") format("woff2");
  }
}

@mixin adaptiveFont($f-min, $f-max, $w-max, $w-min: 0) {
  @media (min-width: $w-min) {
    font-size: adaptiveSize($f-min, $f-max);
  }

  @media (min-width: $w-max) {
    font-size: $f-max + px;
  }
}
