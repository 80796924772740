.pci-dss {
  padding: 1rem 0;
}

@media (max-width: 575px) {
  .custom-width-1 {
    width: 130px;
  }
  .pci-dss {
    padding: 1rem .5rem;
  }
}

.pointer {
  cursor: pointer;
}

.card {
  width: 262px;
  border-radius: 7px;
  padding: 4px 9px;
  height: 36px;
}

.block-pay-fixed {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  border-top: 1px solid #ccc;
  z-index: 10;
  background-color: #fff;
  bottom: 0;
  left: 0;
}

.comment-write {
  transform: scale 1;
}

.error-number {
  left: 0;
  bottom: 5px;
}

.top-body-check {
  padding-bottom: 30px!important;
  background-image: url(../img/top-clip.svg), radial-gradient(circle at 50% calc(100% + 7px), transparent 48px, #fff 48px);
  background-size: 100px, 100% calc(100% + 3px);
  background-repeat: no-repeat;
  background-position: center calc(100% + 2px);
}

.bottom-body-check {
  padding-top: 50px!important;
  background-image: url(../img/bottom-clip.svg), radial-gradient(circle at 50% -5px, transparent 46px, #fff 46px);
  background-size: 100px, 100% calc(100% + 3px);
  background-repeat: no-repeat;
  background-position: center -2px;
}

.reciept-element:not(:nth-child(2)) {
  border-top: 1px dashed #6e808f;
}

.top-clip,
.bottom-clip {
  position: absolute;
  background: url("../img/dash-line.svg") repeat-x;
  width: 100%;
  height: 16px;
  left: 0;
}

.top-clip {
  top: -8px;
}

.bottom-clip {
  bottom: -16px;
}

.collapsing {
  transition: height .4s ease;
}
