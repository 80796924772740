input[name="card"] + span:before {
  content: '';
  position: absolute;
  left: 0;
  top: 1px;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  border: 2px solid #78c2fe;
  transition: all .3s ease;
}

input[name="card"]:checked + span:before {
  background-color: #78c2fe;
}

.input-cash,
.input-bonus {
  max-width: 200px;
  text-align: center;
  padding: .25rem;
  border-radius: 8px;
  border: 1px solid #d0d7de;
  outline: 0;
  box-shadow: inset 0 0 0 30px #fff;
  -webkit-appearance: textfield;
  appearance: textfield;
  transition: .4s;
}

.input-cash {
  width: 140px;
  margin-left: 18px;
}

.input-bonus {
  width: 80px;
}


.textarea-comment {
  outline: none;
  border-color: #d6d6d6;
  border-radius: 7px;
  min-height: 34px;
  max-height: 100px;
  height: 90px;
  width: 262px;
}
.textarea-comment:hover,
.input-cash:hover,
.input-bonus:hover {
  border-color: var(--primary);
}
.textarea-comment:focus,
.input-cash:focus,
.input-bonus:focus {
  border-color: var(--primary);
  box-shadow: 0 0 5px 0px #8ccaff;
  outline: none;
}

.form {
  max-width: 380px;
  margin: auto;
  position: relative;
}

.card-text-input {
  font-size: 12px!important;
  letter-spacing: 2px;
  box-shadow: none;
}

@media screen and (min-width: 350px) {
  .card-text-input {
    font-size: 16px !important;
    padding-top: 2px;
  }
}

.check {
  cursor: pointer;
  position: relative;
  margin: auto 0;
  width: 12px;
  height: 15px;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
}

.check svg {
  position: relative;
  z-index: 1;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
  /*stroke: var(--primary);*/
  stroke-width: 1.5;
  transform: translate3d(0, -5px, 0);
  transition: all 0.2s ease;
}
.check svg path {
  stroke-dasharray: 60;
  stroke-dashoffset: 0;
}
.check svg polyline {
  stroke-dasharray: 22;
  stroke-dashoffset: 66;
}
.check:hover svg {
  /*stroke: var(--primary);*/
}

.check-default{
  display: none;
}
.check-default:checked + .check svg {
  /*stroke: var(--primary);*/
}
.check-default:checked + .check svg path {
  stroke-dashoffset: 60;
  transition: all 0.3s linear;
}
.check-default:checked + .check svg polyline {
  stroke-dashoffset: 42;
  transition: all 0.2s linear;
  transition-delay: 0.15s;
}


/*Checkbox Separate*/
.check-separate-default {
  max-width: calc(50% - 35px);
}
.check-separate-default label{
  color: var(--primary);
  text-decoration: underline;
  cursor: pointer;
}
.check-separate-default label::before{
  content: '';
  position: absolute;
  left: -13px;
  bottom: 50%;
  transform: translateY(50%);
  width: 24px;
  height: 26px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 1px solid var(--primary);
  transition: background-color .5s ease;
  background-image: url("../img/circle.svg");
  background-repeat: no-repeat ;
  background-position: center;
  background-size: 6px;
}
.check-separate-default input:checked + label::before {
  transition: background-color .5s ease;
  background-color: var(--primary);
  background-image: url("../img/ok.svg");
  background-size: 10px;
}

.form-input-1 {
  border: 1px solid #d6d6d6;
  border-radius: 7px;
  height: 36px;
  width: 262px;
  color: #3e5569;
  box-shadow: none;
  transition: .3s;
}
.form-input-1:hover {
  border-color: var(--primary);
}
.form-input-1:focus {
  border-color: var(--primary);
  box-shadow: 0 0 5px 0px #8ccaff;
  outline: none;
}

.form-input-frame {
  height: 26px;
}

.cvc {
  -webkit-appearance: none;
  transition: .4s;
  border-radius: 4px;
  border: 0;
  border: 1px solid #e8e8e8;
  width: 39px;
  height: 15px;
}
.cvc-2 {
  border: 1px solid transparent;
}
.cvc:hover,
.cvc:focus,
.cvc-2:hover,
.cvc-2:focus {
  border: 1px solid #3e5569;
}

.exp-date {
  -webkit-appearance: none;
  border: 1px solid white;
  transition: .4s;
  border-radius: 4px;
  border: 1px solid #fff;
}


/*@media (min-width: 350px) {*/
/*  .cvc {*/
/*    width: 55px;*/
/*  }*/
/*}*/

.cc-number {
  -webkit-appearance: none;
  border-radius: 4px;
  border: 1px solid white;
  transition: background-color 9999s border .3s box-shadow .3s;
}

.hidden-expdate {
  height: 0;
  width: 0;
  border: 0;
}
