input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
.rc-slider {
  margin: 0 auto;
  position: relative;
  height: 14px;
  padding: 10px 0 20px;
  width: 95%;
  border-radius: 6px;
  -ms-touch-action: none;
  touch-action: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  position: absolute;
  width: 100%;
  background-color: #c6c6c6;
  height: 1px;
  border-radius: 6px;
}
.rc-slider-track {
  position: absolute;
  left: 0;
  height: 1px;
  border-radius: 6px;
  background-color: #c6c6c6;
}
.rc-slider-handle {
  position: absolute;
  width: 14px;
  height: 14px;
  cursor: pointer;
  cursor: -webkit-grab;
  margin-top: -6px;
  cursor: grab;
  border-radius: 50%;
  border: none;
  background-color: #fff;
  box-shadow: 0 0 5px -1px #3e5569;
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}
.rc-slider-handle:focus,
.rc-slider-handle:active {
  outline: none;
  border-color: #57c5f7;
  /*box-shadow: 0 0 5px #57c5f7;*/
  cursor: grabbing;
}
/*.rc-slider-handle-click-focused:focus {*/
/*  border-color: var(--primary);*/
/*}*/
.rc-slider-handle:hover {
  border-color: #57c5f7;
}
.rc-slider-handle:active {

}

/* Frame slider */
.slider-frame {
  width: 208px;
}
