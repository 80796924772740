.btn-primary {
  color: #fff;
  background-color: #248ce4;
  border-color: #248ce4;
}

.btn-primary:hover {
  color: #fff;
  background-color: #177ac5;
  border-color: #177ac5;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus,
.btn-primary:focus,
.btn-outline-primary:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #177ac5;
  border-color: #177ac5;
}

.btn-outline-primary {
  color: #248ce4;
  border-color: #248ce4;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #248ce4;
  border-color: #248ce4;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #248ce4;
  border-color: #248ce4;
}

.btn-pay {
  width: 130px;
  height: 34px;
  border-radius: 6px;
  background-color: #248ce4;
  border-color: #248ce4;
}

.btn-comment {
  border-bottom: 1px solid var(--primary);
  transition: .3s;
  box-shadow: none;
}

.gpay-button.short {
  width: 240px!important;
}

.btn-link,
.btn-link:hover,
.btn-link:focus,
.btn-link:active {
  color: #3e5569;
  text-decoration: none;
}

.btn-check {
  position: absolute;
  left: 50%;
  top: -38px;
  background-color: #fff;
  background-image: url("../img/check.svg");
  background-size: 53%;
  background-position: center;
  background-repeat: no-repeat;
  width: 65px;
  height: 65px;
  filter: drop-shadow(0px 0px 1px #a7aaad);
  -webkit-filter: drop-shadow(0px 0px 1px #a7aaad);
  /*filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, 0.27));*/
  /*-webkit-filter: drop-shadow( 0px 0px 3px rgba(0, 0, 0, 0.27));*/
  transform: translateX(-50%);
  border-radius: 50%;
  border: 0;
  box-shadow: none;
  z-index: 1;
}
.btn-check:focus {
  box-shadow: none;
}

.btn-check-separate {
  background-image: url("../img/separate-check.svg");
  background-size: 73%;
}

.btn-check-top,
.btn-check-top:focus,
.btn-check-bottom,
.btn-check-bottom:focus {
  position: absolute;
  background-color: transparent;
  width: 65px;
  height: 30px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: none;
  border: 0;
  background: url("../img/times.svg") no-repeat center;
  background-size: 14px;
  z-index: 1;
}

.btn-check-top,
.btn-check-top:focus {
  bottom: -5px;
}

.btn-check-bottom,
.btn-check-bottom:focus {
  top: -5px;
}

button:focus {
  outline: none;
}

.btn-close-send {
  top: -2px;
  right: 0;
  width: 35px;
  height: 100%;
}



.container-card {
  width: 100%;
  max-width: 262px;
  border-radius: 9px;
  transition: .2s;
  border: 1px solid #dee2e6;
}
.container-card:hover,
.container-card:focus {
  outline: none;
  border-color: transparent;
  box-shadow: 0 0 20px 0px rgba(62, 85, 105, .25);
}

.container-alter-pay {
  width: 100%;
  max-width: 380px;
}

#GPay-container button,
#apple-pay-button {
  width: 100%!important;
  max-width: 327px;
  display: flex;
  margin: auto;
}
#samsung-pay-button {
  width: 100%;
  max-width: 327px;
  height: 40px;
  border-radius: 5px;
  background: black url('../img/card/samsungpay.svg') no-repeat center;
  background-size: 60%;
  cursor: pointer;
  margin: auto;
}

