@keyframes slide-left {
  from {
    transform: translateX(100px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes bonus-appear {
  from {
    transform: translateX(-65px);
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    transform: translateX(0);
    visibility: visible;
  }
}

@keyframes slide-right {
  from {
    transform: translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes error-shadow {
  from {
    box-shadow: 0 0 0 white;
  }
  50% {
    box-shadow: 0 0 5px 0 #dc3545;
  }
}

.slide-left {
  animation: slide-left .25s ease-in;
}

.slide-right {
  animation: slide-right .25s ease-in;
}

.bonus-appear {
  animation: bonus-appear .25s ease-in;
  animation-fill-mode: forwards;
}
