.radiobutton-card {
  label {
    position: relative;
    padding-left: 28px;
    margin: 0;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: -5px;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      box-shadow: inset 0 0 0 2px $gray-2;
      border: none;
      transition: .3s;
      border-radius: 50%; } }
  input {
    display: none; } }

.radiobutton-foreign {
  label {
    margin: 0;
    cursor: pointer;
    &::before {
      content: '';
      display: inline-block;
      width: 16px;
      height: 16px;
      box-shadow: inset 0 0 0 2px $gray-2;
      border: none;
      transition: .3s;
      border-radius: 50%; } }
  input {
    display: none; } }

.check-2 {
  cursor: pointer;
  position: relative;
  margin: auto 0;
  width: 12px;
  height: 15px;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0);
  svg {
    position: relative;
    z-index: 1;
    fill: none;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: $default;
    stroke-width: 1.5;
    transform: translate3d(0, -5px, 0);
    transition: all 0.2s ease;
    path {
      stroke-dasharray: 60;
      stroke-dashoffset: 0; }
    polyline {
      stroke-dasharray: 22;
      stroke-dashoffset: 66; } }
  &:hover svg {
    stroke: $default; } }

.check-default-2 {
  display: none;
  &:checked + .check-2 svg {
    stroke: $default;
    path {
      stroke-dashoffset: 60;
      transition: all 0.3s linear; }
    polyline {
      stroke-dashoffset: 42;
      transition: all 0.2s linear;
      transition-delay: 0.15s; } } }

.block-form-input {
  display: flex;
  line-height: 1;
  position: relative;
  height: 37px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 6px;
  transition: .3s;
  input {
    outline: none;
    border: none;
    color: #3e5569;
    border-radius: 6px;
    padding: 10px 10px 13px;
    width: 100%; }
  &:not(:focus-within):before {
    content: '';
    position: absolute;
    width: calc(100% - 12px);
    height: 2px;
    bottom: -1px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #999; }
  &:focus-within {
    border-color: #78c2fe;
    box-shadow: 0 0 3px #78c2fe; }
  .country-number {
    border-right: 1px solid #ccc; }
  .send-sms {
    position: absolute;
    right: -1px;
    top: -1px;
    border-top-left-radius: 0;
    height: 37px; } }

.request-input {
  background: transparent;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid $gray-1;
  padding: 9px 6px 0;
  transition: .3s;
  font-size: 18px;
  line-height: {}
  &:hover,
  &:focus {
    border-color: $default; } }

.input-bonus-frame {
  //@include size(60, 20)
  width: 60px;
  text-align: right;
  padding: 0 4px;
  border-radius: 3px;
  border: 1px solid #d0d7de;
  outline: 0;
  box-shadow: none;
  appearance: none;
  transition: .4s;
  font-size: 18px;
  line-height: $lh-1;
  &:hover {
    border-color: $primary; }
  &:focus {
    border-color: var(--primary);
    box-shadow: 0 0 5px 0px #8ccaff;
    outline: none; } }
