.fiskal-check {
  max-width: 460px!important;
  min-width: 300px;
  font-family: "Open Sans", serif;
  margin: 20px 0;
  padding: 0 15px!important;
  &-body {
    padding-top: 20px;
    width: 100%; } }

.ur-data {
  display: flex;
  flex-direction: column;
  padding: 25px 0 20px; }

.top-circles {
  background: url("../img/circle.svg") repeat-x;
  background-size: 16px 10px;
  width: calc(100% - 4px);
  height: 11px;
  top: -4px;
  left: 4px; }

.bottom-circles {
  background: url("../img/circle.svg") repeat-x;
  background-size: 16px 10px;
  width: calc(100% - 4px);
  height: 9px;
  bottom: -4px;
  left: 4px; }

