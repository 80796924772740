@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500&display=swap&subset=cyrillic');

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600&display=swap&subset=cyrillic-ext');

@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500&subset=cyrillic-ext);


.font-7 {
  font-size: 7px;
}
.font-8 {
  font-size: 8px;
}
.font-10 {
  font-size: 10px;
}
.font-11 {
  font-size: 11px;
}
.font-12 {
  font-size: 12px;
}
.font-13 {
  font-size: 13px;
}
.font-14 {
  font-size: 14px;
}
.font-15 {
  font-size: 15px;
}
.font-16 {
  font-size: 16px;
}
.font-17 {
  font-size: 17px;
}
.font-18 {
  font-size: 18px;
}
.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-23 {
  font-size: 23px;
}
.font-24 {
  font-size: 24px;
}
.font-25 {
  font-size: 25px;
}
.font-26 {
  font-size: 26px;
}
.font-27 {
  font-size: 27px;
}
.font-30 {
  font-size: 30px;
}
.font-32 {
  font-size: 32px;
}
.font-36 {
  font-size: 36px;
}
.font-39 {
  font-size: 39px;
}
.font-40 {
  font-size: 39px;
}
.font-50 {
  font-size: 50px;
}

.lh-1 {
  line-height: 1;
}

.lh-3 {
  line-height: 1.2;
}

.font-medium {
  font-weight: 500;
}

.montserrat {
  font-family: "Montserrat", monospace, serif;
  font-weight: 400;
}
.montserrat-med {
  font-family: "Montserrat", monospace;
  font-weight: 500;
}

.myriad-reg {
  font-family: "Myriad Regular", monospace;
}

.open-sans-reg {
  font-family: "Open Sans", sans-serif;
}

.open-sans-semi {
  font-family: "Open Sans Semibold", sans-serif;
}

.text-secure {
  font-size: 10px;
}
@media screen and (min-width: 400px){
  .text-secure {
    font-size: 13px;
  }
}
