%service-btn {
  display: block;
  margin: 0 auto;
  background: #1971bf;
  color: #fff;
  line-height: 1;
  transition: .3s;
  font-size: 13px;
  border-radius: 6px;
  border: none;
  &:hover,
  &:active {
    opacity: .85; } }

.service {
  &-modal {
    padding: 6px 6px 6px 20px; }
  .modal-dialog {
    max-width: 420px; }

  &-logo-telepay {
    @include size(200, 48);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: -11px;
    margin-top: 15px;
    margin-bottom: 28px; }

  &-number-title {
    margin-bottom: 16px; }

  &-input {
    background: #ebf5fa;
    border-radius: 6px;
    letter-spacing: normal;
    padding: 9px 10px 6px;
    &:active,
    &:focus {
      box-shadow: 0 0 0 0.2rem rgba(33, 150, 243, .25); } }

  &-label {
    margin-bottom: 0;
    padding-bottom: 5px;
    color: #333;
    line-height: 1;
    font-size: 18px; }

  &-body {
    max-width: 320px; }

  &-field-help {
    padding-top: 8px; }

  &-form-fields,
  &-form-fields-submitting {
    margin-bottom: 20px;
    padding: 20px 17px;
    border-radius: 6px; }

  &-form-fields-submitting {
    background: #f4f4f6; }

  .btn-service {
    &-back {
      @extend %service-btn;
      padding: 10px 14px; }

    &-continue {
      @extend %service-btn;
      padding: 22px 27px 21px; }

    &-pay {
      @extend %service-btn;
      padding: 22px 27px 21px; } }

  &-powered-by-img {
    background: url("../../img/logo.png") no-repeat center/contain;
    @include size(65, 25); }

  &-text-field {
    padding-bottom: 5px;
    &:last-of-type {
      padding-bottom: 0; } }

  &-static-sum {
    background: #ebf5fa;
    border-radius: 6px;
    letter-spacing: normal;
    padding: 9px 10px 6px;
    color: $default; } }


@include placeholder('service-input') {
  font-size: 14px;
  letter-spacing: normal;
  color: #808080; }

@media (min-width: 380px) {
  .service-modal {
    padding-left: 48px; } }

@media (min-width: 436px) {
  .service .modal-dialog {
    margin-left: auto;
    margin-right: auto; } }

@media (min-width: 720px) {
  .service-text-field {
    padding-bottom: 17px; } }
