.text-danger {
  color: $danger; }

.text-gray-4 {
  color: $gray-4; }

.card-text-frame {
  font-size: 14px !important; }

.neo-sans-light {
  font-family: "NeoSans Pro Light", serif; }

.neo-sans-medium {
  font-family: "NeoSans Pro Medium", serif; }

.neo-sans-regular {
  font-family: "NeoSans Pro Regular", serif; }

.mts-sans {
  font-family: "MTS Sans Regular", serif; }

.mts-sans-bold {
  font-family: "MTS Sans Bold", sans-serif; }

.mts-sans-medium {
  font-family: "MTS Sans Medium", serif; }

.rub {
  font-family: "rouble", serif; }
