.pay-main-container {
  display: grid;
  height: 100%;
  grid-template-rows: 1fr;
  min-height: 100vh; }

.container-height {
  min-height: 100%;
  height: 100%; }

.container-body {
  padding: 0 15px; }

.container-frame {
  &-header {
    padding: 11px;
    margin: 0 auto; }
  &-body {
    padding: 0 11px 11px; } }

