@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url(./webfonts/fa-light-300.woff2) format("woff2"),
  url(./webfonts/fa-light-300.woff) format("woff"),
  url(./webfonts/fa-light-300.ttf) format("truetype");
}

@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url(./webfonts/fa-solid-900.woff2) format("woff2"),
  url(./webfonts/fa-solid-900.woff) format("woff"),
  url(./webfonts/fa-solid-900.ttf) format("truetype")
}

.fa,
.fab,
.fad,
.fal,
.far,
.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1
}

.fa,
.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900
}

.fa-sign-in:before {
  content: "\f090"
}

.fa-exchange-alt:before {
  content: "\f362"
}

.fa-credit-card:before {
  content: "\f09d"
}

.fa-ruble-sign:before {
  content: "\f158"
}

.fa-chevron-left:before {
  content: "\f053"
}

.fa-chevron-up:before {
  content: "\f077"
}

.fa-chevron-down:before {
  content: "\f078"
}

.fa-qrcode:before {
  content: "\f029"
}

.fa-info-circle:before {
  content: "\f05a"
}

.fa-receipt:before {
  content: "\f543"
}

.fa-caret-right:before {
  content: "\f0da"
}

.fa-caret-down:before {
  content: "\f0d7"
}
