.modal-header {
  border-bottom: none;
  .close {
    color: #3e5569;
    padding: 1rem;
    [aria-hidden="true"] {
      font-size: 2.4rem;
      line-height: .1;
      font-family: "Open Sans";
      color: $default; } } }
