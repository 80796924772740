.btn {
  padding: 0;
  line-height: 1;
  &:focus {
    box-shadow: none; }

  &-myinvoice {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 212px;
    height: 38px;
    border-radius: 6px;
    background-color: #78c2fe!important;
    border-color: #78c2fe!important;
    border: 0;
    &:hover,
    &:active {
      background-color: #34a7ff!important; } }

  &-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: $card-width;

    border-radius: 7px;
    height: 35px;
    padding: 5px 8px;
    transition: .2s;
    border: 1px solid #dee2e6;
    &:hover,
    &:focus,
    &.hover,
    &.focus {
      outline: none;
      //border-color: transparent
 } }      //box-shadow: 0 0 20px 0px rgba(62, 85, 105, .25)

  &-pay-frame {
    height: 30px; }

  &-share-qr {
    position: absolute;
    right: 13px;
    top: 13px; }

  &-sbp,
  &-sbp:hover,
  &-sbp:active,
  &-sbp:focus {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 40px;
    background: white;
    border: 1px solid $gray-5!important;
    padding: 5px 8px;
    color: $default!important;
    font-size: 12px;
    margin: .25rem auto;
    max-width: 327px;
    font-family: "Myriad Regular";
    div {
      position: absolute;
      left: 50%;
      top: calc(50% + 1px);
      transform: translate(-50%, -50%);
      padding-top: 1px!important;
      width: 100%; } }
  &-sbp:active {
    background: rgba(36, 140, 228, .3)!important; } }



@media (min-width: 704px) {
  .btn-pay-frame-back {
    width: 262px;
    margin: 0 auto; } }


.gpay-card-info-container {
  max-width: 327px;
  width: 100%;
  height: 40px;
  border-radius: 5px;
  display: flex;
  margin: auto; }
