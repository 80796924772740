@font-face {
  font-family: "Myriad Regular";
  font-weight: normal;
  font-style: normal;

  src: url("./Myriad/Myriad.woff") format("woff"),
  url("./Myriad/Myriad.woff2") format("woff2");
}

@font-face {
  font-family: "NeoSans Pro Light";
  font-weight: 300;
  font-style: normal;

  src: url("./NeoSansPro/NeoSansPro-Light.woff") format("woff"),
  url("./NeoSansPro/NeoSansPro-Light.woff2") format("woff2");
}

@font-face {
  font-family: "NeoSans Pro Regular";
  font-weight: 400;
  font-style: normal;

  src: url("./NeoSansPro/NeoSansPro-Regular.woff") format("woff"),
  url("./NeoSansPro/NeoSansPro-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "NeoSans Pro Medium";
  font-weight: 500;
  font-style: normal;

  src: url("./NeoSansPro/NeoSansPro-Medium.woff") format("woff"),
  url("./NeoSansPro/NeoSansPro-Medium.woff2") format("woff2");
}

@font-face {
  font-family: "MTS Sans Regular";
  font-weight: 400;
  font-style: normal;

  src: url("./MTS/MTSSans-Regular.otf") format("opentype"),
  url("./MTS/MTSSans-Regular.otf") format("opentype");
}

@font-face {
  font-family: "MTS Sans Bold";
  font-weight: 600;
  font-style: normal;

  src: url("./MTS/MTSSans-Bold.otf") format("opentype"),
  url("./MTS/MTSSans-Bold.otf") format("opentype");
}

@font-face {
  font-family: "MTS Sans Medium";
  font-weight: 500;
  font-style: normal;

  src: url("./MTS/MTSSans-Medium.otf") format("opentype"),
  url("./MTS/MTSSans-Medium.otf") format("opentype");
}

@font-face {
  font-family: "rouble";
  src: url("./rouble/rouble.otf") format("opentype");
}
