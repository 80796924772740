.border-primary {
  border-color: var(--primary) !important;
}

.out-none,
.out-none:focus,
.out-none:active {
  outline: none;
}

.error-shadow {
  animation: error-shadow .4s ease-out forwards;
}

.border-bottom-gray {
  border-bottom: 2px solid #f2f2f2;
}
